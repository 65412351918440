import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

var mixpanel = require('mixpanel-browser')

export default class extends Controller {
    static values = {
        refresh: Number,
        token: String
    }

    connect() {
        let controller = this
        this.element[this.identifier] = this
        StimulusReflex.register(controller)
    }

    init_mixpanel(token) {
        mixpanel.init(token, {ignore_dnt: true})
    }

    send_event(event) {
        if (mixpanel.config) {
            let event_name = event.target.getAttribute('data-event-name')
            let hash = event.target.getAttribute('data-hash')
            let mxident = document.querySelector("meta[name='mxident']")
            let mxhash = document.querySelector("meta[name='mxhash']")
            if (mxhash !== null) {
                hash = mxhash.content
            }
            if (mxident !== null) {
                mixpanel.identify(mxident.content)
            }
            if (hash) {
                mixpanel.track(event_name, JSON.parse(hash))
            } else {
                mixpanel.track(event_name)
            }
        }
    }

    refreshValueChanged() {
        if (this.tokenValue) {
            if (this.refreshValue === 1) {
                this.init_mixpanel(this.tokenValue)
                this.refreshValue = 0
            }
        }
    }
}